<template>
    <div>
        <div class="projectLayers-container">
            <div class="header-container">
                <ul id="project-layers" class="tocList layers-container">
                    <li><geo-json-layers /></li>
                    <li><treeview :zoomLevel="zoomLevel" /></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import treeview from './TableOfContents/treeview.vue';
import GeoJsonLayers from './TableOfContents/GeoJsonLayers.vue';

export default {
    name: 'ProjectLayers',
    components: {
        treeview,
        GeoJsonLayers,
    },
    data() {
        return { zoomLevel: 5 };
    },
    computed: {
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
    },
};
</script>
<style lang="scss" scoped>
.projectLayers-container {
    position: relative;
    float: left;
    height: 100%;
    width: 100%;
}
.layers-container {
    padding: 0 27px 0 19px;
    margin-top: 5px;
}
</style>
